import React from 'react'
import styled from '@emotion/styled';
import {
  Layout,
  SEO,
  FullHeightWrapper,
  Card,
  NavLink
} from '../components/elements';

const StyledCard = styled(Card)`
    a{
        ${tw`underline`}
    }
`

export default function Inscription(){
    return(
        <FullHeightWrapper>
        <SEO title="Inscription à l'annuaire consodurable.ch" description="Grâce à consodurable, ouvrez-vous à de nouvelle oportunités et une nouvelle clientelle" />

            <NavLink to="/" />
            <StyledCard title="Comment s'inscrire">
                Pour s'inscrire il suffit de télécharger <a target="_blank" href="/inscription_consodurable.pdf">ce formulaire</a>
                Et de l'envoyer à <a href="mailto:inscription@consodurable.ch">inscription@consodurable.ch</a>
                Nous vous ajouterons dès que possible
            </StyledCard>
            <StyledCard title="Quel sont les critères ?">
                Vous devez justifiez votre emprunte écologique, quelles sont les mesures que vous prenez pour un développement durable ?
                Par exemple:
                <ul>
                    <li>Production bio</li>
                    <li>Economies d'eau</li>
                    <li>Utilisation de procédés alternatifs, moins polluant</li>
                    <li>Eviter les chaînes d'actions</li>
                    <li>Promotion du "local"</li>
                    <li>Respect de l'environnement</li>
                </ul>
            </StyledCard>
        </FullHeightWrapper>
    )
}